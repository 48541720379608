import React from 'react'
import { Navbar } from 'react-bootstrap'
import './ProjectHeader.css'
import { Check2Circle, ExclamationCircle } from 'react-bootstrap-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useLocation } from 'react-router-dom'

let ProjectHeader = ({projectName, connected, online}) => {
    const location = useLocation()

    const renderTooltip = props => (
        <Tooltip {...props}>{online !== 1 && connected === true ? "You and " + (online - 1) + " collaborator" + ((online - 1) > 2 ? "s " : " ") + "online" : "Realtime status"}</Tooltip>
    );

    return (
        <Navbar bg="black" className='sub-nav'>
            <p className={'paragraph1'}>{'Project:'}</p>
            <p className={'paragraph2'}>{projectName}</p>
            <Navbar.Collapse className="justify-content-end">
                <OverlayTrigger placement="bottom" overlay={ renderTooltip }>
                    <Navbar.Text className={'connection-status'} data-toggle="tooltip">
                        <a className='tracing-link' href={`${location.pathname}/tracing`}>See Traces</a>
                        {connected && <Check2Circle className={'connection-status-ok'}/>}
                        {!connected && <ExclamationCircle className={'connection-status-nok'}/>}
                    </Navbar.Text>
                </OverlayTrigger>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default ProjectHeader;
