import { useQuery } from '@tanstack/react-query'
import { authHeader } from "../../helpers/auth-header";
import axios from "axios";
import constants from "../../constants";

function useCurrentExercises() {
    return useQuery(
        {
            queryKey: [`current-exercises`],
            queryFn: async () => {
                const requestConfig = {
                    headers: authHeader(),
                }
                const response = await axios.get(`${ constants().apiUrl }/exercise/current`, requestConfig)
                return response.data.data
            },
        }
    )
}

function useCompletedExercises() {
    return useQuery(
        {
            queryKey: [`completed-exercises`],
            queryFn: async () => {
                const requestConfig = {
                    headers: authHeader(),
                }
                const response = await axios.get(`${ constants().apiUrl }/exercise/completed`, requestConfig)
                return response.data.data
            },
        }
    )
}

function useCreatedExercises() {
    return useQuery(
        {
            queryKey: [`created-exercises`],
            queryFn: async () => {
                const requestConfig = {
                    headers: authHeader(),
                }
                const response = await axios.get(`${ constants().apiUrl }/exercise/created`, requestConfig)
                return response.data.data
            },
        }
    )
}

function useExerciseArtefacts(exerciseId) {
    return useQuery(
        {
            queryKey: [`exercise-artefacts`, exerciseId],
            queryFn: async () => {
                const requestConfig = {
                    headers: authHeader(),
                }
                const response = await axios.get(`${ constants().apiUrl }/exercise/${ exerciseId }`, requestConfig)
                return response.data.data
            },
        }
    )
}

function useExerciseTraces(exerciseId, exerciseAttemptId) {
    return useQuery(
        {
            queryKey: [`exercise-traces`, exerciseId, exerciseAttemptId],
            queryFn: async () => {
                const requestConfig = {
                    headers: authHeader(),
                }
                const response = await axios.get(`${ constants().apiUrl }/exercise/${ exerciseId }/attempt/${ exerciseAttemptId }`, requestConfig)
                return response.data.data
            },
        }
    )
}

function useExerciseAttemptResult(exerciseId, exerciseAttemptId) {
    return useQuery(
        {
            queryKey: [`exercise-result`, exerciseAttemptId],
            queryFn: async () => {
                const requestConfig = {
                    headers: authHeader(),
                }
                const response = await axios.get(`${ constants().apiUrl }/exercise/${ exerciseId }/result/${ exerciseAttemptId }`, requestConfig)
                return response.data.data
            },
        }
    )
}

function useExerciseAttempts(exerciseId) {
    return useQuery(
        {
            queryKey: [`exercise-results`, exerciseId],
            queryFn: async () => {
                const requestConfig = {
                    headers: authHeader(),
                }
                const response = await axios.get(`${ constants().apiUrl }/exercise/${ exerciseId }/result`, requestConfig)
                return response.data.data
            },
        }
    )
}

function useExerciseResultOverview(exerciseId) {
    return useQuery(
        {
            queryKey: [`exercise-results-overview`, exerciseId],
            queryFn: async () => {
                const requestConfig = {
                    headers: authHeader(),
                }
                const response = await axios.get(`${ constants().apiUrl }/exercise/${ exerciseId }/result/overview`, requestConfig)
                return response.data.data
            },
        }
    )
}

async function createTrace(body, exerciseId, exerciseAttemptId) {
    const requestConfig = { headers: authHeader() }
    await axios.post(`${ constants().apiUrl }/exercise/${ exerciseId }/attempt/${ exerciseAttemptId }`, body, requestConfig)
}

async function submitExercise(exerciseId, exerciseAttemptId) {
    const requestConfig = { headers: authHeader() }
    await axios.get(`${ constants().apiUrl }/exercise/${ exerciseId }/attempt/${ exerciseAttemptId }/submit`, requestConfig)
}

async function createExercise(projectId, exerciseName, allowedUsers) {
    const body = {
        projectId,
        exerciseName,
        allowedUsers,
    }
    const requestConfig = { headers: authHeader() }
    return await axios.post(`${ constants().apiUrl }/exercise/create`, body, requestConfig)
}

async function createExerciseAttempt(exerciseId) {
    const requestConfig = { headers: authHeader() }
    const response = await axios.post(`${ constants().apiUrl }/exercise/${ exerciseId }`, {}, requestConfig)
    return response.data.data
}


export {
    useCurrentExercises,
    useCompletedExercises,
    useCreatedExercises,
    useExerciseArtefacts,
    useExerciseTraces,
    createTrace,
    submitExercise,
    createExercise,
    useExerciseAttemptResult,
    useExerciseAttempts,
    createExerciseAttempt,
    useExerciseResultOverview
}