import { EntityType } from "../tracing/Types";

const entityColors = {
    [EntityType.CLASS]: '#638ef3',
    [EntityType.ACTOR]: '#56cdb0',
    [EntityType.ATTRIBUTE]: '#cada4e',
    [EntityType.RELATIONSHIP]: '#d69b57',
    [EntityType.US]: '#e44f4f',
}

const dmNodes = [
    {
        "id": 0,
        "type": "CLASS",
        "name": "aircraft",
    },
    {
        "id": 1,
        "type": "ACTOR",
        "name": "aircraft manager",
    },
]

const dmLinks = [{
    "id": 2,
    "type": "RELATIONSHIP",
    "name": "create",
    "mainEntities": [],
    "links": [
        {
            "from": 0,
            "to": 1
        },
    ],
    "source": 1,
    "target": 0,
}]

const actorTrace = {
    "id": 0,
    "targetArtefactId": 0,
    "eSourceArtefacts": [
        {
            "traceId": 0,
            "userStoryFragmentId": 2
        },
        {
            "traceId": 0,
            "userStoryFragmentId": 3
        }
    ],
    "eEntity": {
        "id": 1,
        "name": "aircraft manager",
        "type": "ACTOR",
    }
}

const classTrace = {
    "id": 1,
    "targetArtefactId": 0,
    "eSourceArtefacts": [
        {
            "traceId": 1,
            "userStoryFragmentId": 10
        }
    ],
    "eEntity": {
        "id": 0,
        "name": "aircraft",
        "type": "CLASS",
    }
}

const classTrace2 = {
    "id": 2,
    "targetArtefactId": 0,
    "eSourceArtefacts": [
        {
            "traceId": 2,
            "userStoryFragmentId": 19
        }
    ],
    "eEntity": {
        "id": 0,
        "name": "aircraft",
        "type": "CLASS",
    }
}

const relationshipTrace = {
    "id": 3,
    "targetArtefactId": 2,
    "eSourceArtefacts": [
        {
            "traceId": 3,
            "userStoryFragmentId": 8
        }
    ],
    "eEntity": {
        "id": 2,
        "name": "create",
        "type": "RELATIONSHIP",
    }
}

const graphData = [
    [],
    [actorTrace],
    [actorTrace, classTrace],
    [actorTrace, classTrace, relationshipTrace],
    [actorTrace, classTrace, classTrace2],
    [actorTrace, classTrace, classTrace2, relationshipTrace],
]

const steps = [
    {
        top: 'Welcome to StoryScreen Trace',
        center: 'StoryScreen Trace aims to help you learn the concepts of software and requirements traceability.',
        bottom: 'Traceability is a technique that is crucial to guarantee the completeness of security dependent applications.'
    },
    {
        top: 'The goal of traceability is to link from one type of artefact to another one.',
        center: 'In StoryScreen Trace we focus on traceability between requirements and conceptual models.',
        bottom: 'Or to be more specific, between user stories and domain models.'
    },
    {
        top: 'In this case, the user stories serve as source artefact, while the domain model serves as target artefact.',
        center: 'The goal is now to connect elements of the two artefacts with a trace link.',
        bottom: 'This allows us to trace back to a source for a specific target artefact. And this determine how this artefact was created and originated.',
    },
    {
        top: 'Let’s start with source artefacts, let’s start with a user story. A user story is a simple sentence, for example:',
        bottom: 'It serves as the main source artefact.',
    },
    {
        top: 'User stories can be split into pieces',
        bottom: null
    },
    {
        top: 'or',
        bottom: null
    },
    {
        top: 'As you can see, it is possible to assign parts of a user story to conceptual definitions like “class” or “relationship”.',
        bottom: null
    },
    {
        top: 'In StoryScreen Trace the user stories will be presented in a list on the left side.',
        bottom: 'Each user story will come with a series of indicators (the coloured boxes). These show which kind of conceptual elements have already been traced to the user story.'
    },
    {
        top: 'When an indicator is filled out, it means that a respective artefact type has been traced to this user story.',
        bottom: 'Blue stands for the actor artefact type, green for class and purple for relationship.'
    },
    {
        top: 'By clicking on a user story, the detailed view for it will be shown.',
        bottom: null,
    },
    {
        top: 'You can click on the words of the user story to select them as source artefacts.',
        bottom: 'Try it out!',
    },
    {
        top: 'Selected words will then be surrounded.',
        bottom: null
    },
    {
        top: 'Let’s continue with the target side of things.',
        bottom: 'The target is a domain model, like shown here.'
    },
    {
        top: 'You can select a part of a domain model by clicking on it. You can select objects as well as relationships.',
        bottom: 'Try it out for yourself!'
    },
    {
        top: 'An object will be coloured, to indicate it is selected.',
        bottom: null
    },
    {
        top: 'Now we can combine the source and the target side.',
        bottom: null
    },
    {
        top: 'On the right side, you will see a summary of the artefacts you have selected.',
        bottom: 'If you have selected source and target artefacts, a button will be shown to save the trace. Click it!'
    },
    {
        top: 'You have now created a trace. You can see, that the indicator for actor has automatically been filled. Further, the words in the user story are also marked with the respective colour.',
        bottom: 'On the target side, the traced element will be greyed out, to indicate that it has been traced.'
    },
    {
        top: 'We can now repeat this process for more artefacts.',
        bottom: 'Like for the aircraft class.'
    },
    {
        top: 'Or the create relationship.',
        bottom: 'On the target side, the traced element will be greyed out, to indicate that it has been traced.'
    },
    {
        top: null,
        bottom: null
    },
    {
        top: 'We have now added traces for the complete target artefact.',
        bottom: 'And we also have traces for all types for user story 1, as you can see in the indicators.'
    },
    {
        top: 'Now it’s your turn! You’ll start with a user story…',
        bottom: '…and a simple domain model.'
    },
    {
        top: 'Your task is to create traces between the user story and the domain model.',
        bottom: 'Or in other words: Trace the elements of the domain model back to the user story.'
    },
    {
        top: 'To create a trace, first select the element in the domain model which is target of the trace.',
        bottom: "Let's start with the actor."
    },
    {
        top: 'Next, select the source of the trace. For this, click on the words of the user story, which are relevant.',
        bottom: null
    },
    {
        top: 'Finally, click on “save trace”.',
        bottom: null
    },
    {
        top: 'Congratulations, You have created your first trace!',
        bottom: 'On the traceability graph, you can now see the trace you just created. The “Aircraft Manager” element is now also marked (gray), to indicate that it has been traced.'
    },
    {
        top: 'Now, add the class!',
        bottom: null
    },
    {
        top: 'Well done!',
        bottom: null
    },
    {
        top: 'You can add multiple traces to the same target artefact. Add a second trace to the aircraft object!',
        bottom: null
    },
    {
        top: 'Nice! Finally, add a trace to the relationship!',
        bottom: null
    },
    {
        top: 'Good job! You have traced the completed domain model!',
        bottom: null
    },
    {
        top: 'You have completed the tutorial.',
        bottom: "Click on 'finish' to head back to the exercise overview."
    }
]

const subArtefacts = [
    {
        "id": 0,
        "index": 0,
        "name": "As"
    },
    {
        "id": 1,
        "index": 1,
        "name": "an"
    },
    {
        "id": 2,
        "index": 2,
        "name": "Aircraft"
    },
    {
        "id": 3,
        "index": 3,
        "name": "Manager"
    },
    {
        "id": 4,
        "index": 4,
        "name": ","
    },
    {
        "id": 5,
        "index": 5,
        "name": "I"
    },
    {
        "id": 6,
        "index": 6,
        "name": "want"
    },
    {
        "id": 7,
        "index": 7,
        "name": "to"
    },
    {
        "id": 8,
        "index": 8,
        "name": "create"
    },
    {
        "id": 9,
        "index": 9,
        "name": "an"
    },
    {
        "id": 10,
        "index": 10,
        "name": "aircraft"
    },
    {
        "id": 11,
        "index": 11,
        "name": "so"
    },
    {
        "id": 12,
        "index": 12,
        "name": "that"
    },
    {
        "id": 13,
        "index": 13,
        "name": "the"
    },
    {
        "id": 14,
        "index": 14,
        "name": "company"
    },
    {
        "id": 15,
        "index": 15,
        "name": "can"
    },
    {
        "id": 16,
        "index": 16,
        "name": "store"
    },
    {
        "id": 17,
        "index": 17,
        "name": "all"
    },
    {
        "id": 18,
        "index": 18,
        "name": "existing"
    },
    {
        "id": 19,
        "index": 19,
        "name": "aircraft"
    },
    {
        "id": 20,
        "index": 20,
        "name": "in"
    },
    {
        "id": 21,
        "index": 21,
        "name": "the"
    },
    {
        "id": 22,
        "index": 22,
        "name": "system"
    },
    {
        "id": 23,
        "index": 23,
        "name": "."
    }
]

const selectableSourceArtefact = {
    '7': { readonly: true },
    '8': { readonly: true },
    '9': { readonly: true },
    '10': {
        readonly: false,
        ids: [0, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
    },
    '11': {
        readonly: false,
        ids: [0, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
    },
    '12': { readonly: true },
    '13': { readonly: true },
    '14': { readonly: true },
    '15': { readonly: true },
    '16': { readonly: true },
    '17': {
        readonly: true,
        ids: [],
    },
    '18': {
        readonly: true,
        ids: [],
    },
    '19': {
        readonly: true,
        ids: [],
    },
    '20': {
        readonly: true,
        ids: [],
    },
    '21': {
        readonly: true,
        ids: [],
    },
    '22': {
        readonly: true,
        ids: [],
    },
    '23': {
        readonly: true,
        ids: [],
    },
    '24': {
        readonly: true,
        ids: [],
    },
    '25': {
        readonly: false,
        ids: [2, 3],
    },
    '26': {
        readonly: true,
        ids: [],
    },
    '27': {
        readonly: true,
        ids: [],
    },
    '28': {
        readonly: false,
        ids: [10],
    },
    '29': {
        readonly: false,
        ids: [],
    },
    '30': {
        readonly: false,
        ids: [19],
    },
    '31': {
        readonly: false,
        ids: [8],
    },
    '32': {
        readonly: false,
        ids: [],
    },
    '33': {
        readonly: false,
        ids: [],
    },
}

const selectableTargetArtefact = {
    '12': { readonly: true },
    '13': {
        readonly: false,
        ids: [0, 1, 2]
    },
    '14': {
        readonly: false,
        ids: [0, 1, 2]
    },
    '15': {
        readonly: true,
        ids: [1]
    },
    '16': {
        readonly: true,
        ids: [1]
    },
    '17': {
        readonly: true,
        ids: [1]
    },
    '18': { readonly: true },
    '19': {
        readonly: false,
        ids: [0]
    },
    '20': { readonly: true },
    '21': {
        readonly: false,
        ids: [0]
    },
    '22': {
        readonly: false,
        ids: [2]
    },
    '23': { readonly: true },
    '24': {
        readonly: false,
        ids: [1]
    },
    '25': {
        readonly: true,
        ids: [1]
    },
    '26': {
        readonly: true,
        ids: []
    },
    '27': {
        readonly: false,
        ids: []
    },
    '28': {
        readonly: false,
        ids: [0]
    },
    '29': {
        readonly: false,
        ids: []
    },
    '30': {
        readonly: false,
        ids: [0]
    },
    '31': {
        readonly: false,
        ids: [2]
    },
    '32': {
        readonly: false,
        ids: []
    },
    '33': {
        readonly: false,
        ids: []
    },
}

const showNextButton = {
    '17': true,
    '19': true,
    '21': true,
    '22': true,
}

const goRightDisabled = [10, 13, 16, 24, 25, 26, 28, 30, 31]

const userStory = {
    "id": 1061,
    "exerciseId": 97,
    "uniqueId": 1,
    "content": "As an Aircraft Manager, I want to create an aircraft so that the company can store all existing aircraft in the system.",
    "eUserStoryFragments": [
        {
            "id": 0,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "As",
            "position": 0
        },
        {
            "id": 1,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "an",
            "position": 1
        },
        {
            "id": 2,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "Aircraft",
            "position": 2
        },
        {
            "id": 3,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "Manager",
            "position": 3
        },
        {
            "id": 4,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": ",",
            "position": 4
        },
        {
            "id": 5,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "I",
            "position": 5
        },
        {
            "id": 6,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "want",
            "position": 6
        },
        {
            "id": 7,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "to",
            "position": 7
        },
        {
            "id": 8,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "create",
            "position": 8
        },
        {
            "id": 9,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "an",
            "position": 9
        },
        {
            "id": 10,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "aircraft",
            "position": 10
        },
        {
            "id": 11,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "so",
            "position": 11
        },
        {
            "id": 12,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "that",
            "position": 12
        },
        {
            "id": 13,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "the",
            "position": 13
        },
        {
            "id": 14,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "company",
            "position": 14
        },
        {
            "id": 15,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "can",
            "position": 15
        },
        {
            "id": 16,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "store",
            "position": 16
        },
        {
            "id": 17,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "all",
            "position": 17
        },
        {
            "id": 18,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "existing",
            "position": 18
        },
        {
            "id": 19,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "aircraft",
            "position": 19
        },
        {
            "id": 20,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "in",
            "position": 20
        },
        {
            "id": 21,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "the",
            "position": 21
        },
        {
            "id": 22,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": "system",
            "position": 22
        },
        {
            "id": 23,
            "userStoryId": 1061,
            "exerciseId": 97,
            "content": ".",
            "position": 23
        }
    ]
}

export {
    graphData,
    entityColors,
    steps,
    subArtefacts,
    selectableSourceArtefact,
    selectableTargetArtefact,
    dmNodes,
    dmLinks,
    showNextButton,
    goRightDisabled,
    userStory
}